import React from 'react';
import Grid from '@material-ui/core/Grid';
import DesktopNewsItem from "./DesktopNewsItem";
import Icon from '@material-ui/core/Icon';
import ContentTitle from "/components/contentTitle";

function DesktopLatestItems(props) {
  const { items } = props;
  // items.splice(-2, 2);
  return (
    <Grid container style={{ marginBottom: 16 }}>
      <ContentTitle contentTitle="Latest" color="#F9461E" />
      {items.length > 0 && items.map((item, index) => (
        index < 8 ?
        <React.Fragment key={index}>
          <Grid item xs={3}>
            <DesktopNewsItem item={item}  />
          </Grid>  
        </React.Fragment>
        : null
    ))}  
    </Grid>
  );
}


export default DesktopLatestItems;
