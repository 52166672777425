import React from 'react';
import DesktopVideoCards from "/components/Video/DesktopVideoCards";
import MobileVideoCards from "/components/Video/MobileVideoCards";
import ReadAll from './readAll';
import ContentTitle from "/components/contentTitle";
import ReadAllLinkForTab from '/components/Brand/ReadAllLinkForTab';
import { Link } from "routes";

export function DesktopVideoCardsSection(props) {
  const { items, readAllClick, brandProfileName, routeName, paramsName, readAll, color, contentTitle, newsItems, maxNum, removeTitle, brandTab } = props;
  return (
        <div style={{padding: 1, backgroundColor: '#0A0A0A'}}>
          {!removeTitle && 
            <Link route={routeName} params={paramsName}>
              <a className="anchor-link">
                <ContentTitle contentTitle={contentTitle} color={color} style={{display: 'none'}} />
              </a>
            </Link>
          }
          <DesktopVideoCards items={items} newsItems={newsItems} maxNum={maxNum}/>
          {
            brandTab ? 
              <ReadAllLinkForTab 
                tabIndex={2} 
                readAllClick={readAllClick} 
                color={color} 
                readAll={readAll} 
              />
            :
            null
            // <ReadAll items={items} routeName={routeName} paramsName={paramsName} readAll={readAll} color={color} />

          }
        </div>
    );
}

export function MobileVideoCardsSection(props) {
    const { items, routeName, paramsName, readAll, color, contentTitle, newsItems, maxNum, removeTitle, brandTab, readAllClick } = props;
    return (
          <div style={{padding: 0, marginBottom: 10, backgroundColor: '#0A0A0A'}}>
            {!removeTitle && 
              <div style={{ padding: '12px 60px 1px 16px' }}>  
                <Link route={routeName} params={paramsName}>
                  <a className="anchor-link">  
                    <ContentTitle contentTitle={contentTitle} color={color} />
                  </a>
                </Link>
              </div>
            }
            <MobileVideoCards items={items} newsItems={newsItems} maxNum={maxNum} />
            {
            brandTab ? 
              <ReadAllLinkForTab 
                tabIndex={2} 
                readAllClick={readAllClick} 
                color={color} 
                readAll={readAll} 
              />
            :
            null
            // <ReadAll items={items} routeName={routeName} paramsName={paramsName} readAll={readAll} color={color} />
            }
            </div>
      );
  }


