import React, { Component } from "react";
import { bindActionCreators } from "redux";
import withRedux from "next-redux-wrapper";
import withReduxSaga from "next-redux-saga";
import Head from "next/head";
import PropTypes from "prop-types";
import Store from "store";
import {
    // fetchPopularItems,
    fetchPopularPosts,
    fetchPosts,
    fetchTag,
    fetchVideos
} from "store/posts/actions";
import NProgress from "components/NProgress";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import { Router, Link } from "routes";
import Icon from '@material-ui/core/Icon';
import { isMobile } from "react-device-detect";
// import BrandsSlider from '/components/brandsSlider';
// import TagManager from 'react-gtm-module';
import { clearInnityAds } from '../utils/innity';
import MobileFeatureCarousel from '/components/Mobile/MobileFeatureCarousel';
import DesktopFeatureCarousel from '/components/Desktop/DesktopFeatureCarousel';
import MobileNewsItem from "/components/Mobile/MobileNewsItem";
// import DesktopNewsItem from "/components/Desktop/DesktopNewsItem";
// import DesktopPopularItems from "/components/Desktop/DesktopPopularItems";
// import MobilePopularItems from "/components/Mobile/MobilePopularItems";
import DesktopLatestItems from "/components/Desktop/DesktopLatestItems";
import Hidden from '@material-ui/core/Hidden';
import DesktopFeatureCarouselItem from '/components/Desktop/DesktopFeatureCarouselItem';
import getAdSlotByDevicePosition from "../utils/ads";
import ContentTitle from "/components/contentTitle";
import SeoMeta from '/components/seoMeta';
// import Typography from '@material-ui/core/Typography';
// import DesktopVideoCards from "/components/Video/DesktopVideoCards";
// import MobileVideoCards from "/components/Video/MobileVideoCards";
// import ReadAllTagsLink from '/components/Tag/ReadAllTagsLink';
import { DesktopVideoCardsSection, MobileVideoCardsSection } from '/components/CardsSection/videoCardsSection';
import { DesktopArticleCardsSection, MobileArticleCardsSection } from '/components/CardsSection/articleCardsSection';
import {c} from '/utils/log';
import { duwunCoverLogo } from '/utils/common';
// const tagManagerArgs = {
//     gtmId: 'GTM-WCJSTPT',
// }
const PopularComponent = (props) => {
  return(
    <>
      <span style={{ color: '#333', paddingRight: 5, fontWeight: 500 }}>
       {`Popular >`}
        {/* <Icon style={{  paddingTop: 5,
        fontWeight: 'bold',}}>
            keyboard_arrow_right
        </Icon> */}
      </span>
    {props.children}
    </>
  )
}

const styles = theme => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
  }
});

const popularCats = ['sport', 'tech'];//['sport-news', 'news', 'entertainment', 'lifestyle', 'tech', 'career-education'];
class PostsIndex extends Component {

  constructor(props){
    super(props);
    this.state = {
      ssrDone: false,
      adMobileTop:{
          id: 'home-ad-mobile-top',
          networkId: null,
          adUnit: null,
          adSizes: [[320, 100]],
          mobile: true,
          position: 'TOP',
          targetingArguments: null,
      },
      adMobileMiddle:{
          id: 'home-ad-mobile-middle',
          networkId: null,
          adUnit: null,
          adSizes: [[300, 250]],
          mobile: true,
          position: 'MIDDLE',
          targetingArguments: null,
      },
      adMobileBottom:{
          id: 'home-ad-mobile-bottom',
          networkId: null,
          adUnit: null,
          adSizes: [[300, 250]],
          mobile: true,
          position: 'BOTTOM',
          targetingArguments: null,
      },
      adMobileBottomRotate:{
          id: 'home-ad-mobile-rotate',
          networkId: null,
          adUnit: null,
          adSizes: [[300, 250]],
          mobile: true,
          position: 'ROTATE',
          targetingArguments: null,
      },
      adDesktopTop:{
          id: 'div-gpt-ad-desktop-global',
          networkId: null,
          adUnit: null,
          adSizes: [[970, 250]],
          mobile: false,
          position: 'TOP',
          targetingArguments: null,
      },
      adDesktopMiddle:{
          id: 'div-gpt-ad-desktop-middle',
          networkId: null,
          adUnit: null,
          adSizes: [[970, 250]],
          mobile: false,
          position: 'MIDDLE',
          targetingArguments: null,
      },
      adDesktopBottom:{
          id: 'div-gpt-ad-desktop-bottom-0',
          networkId: null,
          adUnit: null,
          adSizes: [[970, 250]],
          mobile: false,
          position: 'BOTTOM',
          targetingArguments: null,
      },
      adDesktopBottomRotate:{
          id: 'div-gpt-ad-desktop-bottom-1',
          networkId: null,
          adUnit: null,
          adSizes: [[970, 250]],
          mobile: false,
          position: 'ROTATE',
          targetingArguments: null,
        }
    };
  }

  static getInitialProps({ store }) {
    // let currentDateTime = new Date().toLocaleString('en-US', {timeZone: 'UTC',  hour12: false})
    const currentDateTime = new Date().toISOString();
    // store.dispatch(fetchPopularItems(['news', 'entertainment', 'sport-news', 'tech', 'election-2020', 'lifestyle'], currentDateTime));
    popularCats.forEach(slug =>
        store.dispatch(fetchPopularPosts(slug, currentDateTime))
    );
    store.dispatch(fetchPosts(currentDateTime));
    // store.dispatch(fetchTag('COVID-19', currentDateTime));
    store.dispatch(fetchVideos(currentDateTime, 'VIDEO', 4));
    return {currentDateTime}
  }

  render() {
    const { videos, tagItems, popularAllItems, posts, links, classes, ads, carousel } = this.props;
    // const covid19Category = this.props.categoriesStore.filter((item) => item.type == 'TAG' && item.slug == 'covid-19');
    // const popularCOVID19 = {
    //   "name": covid19Category && covid19Category.length ? covid19Category[0].name : "COVID-19",
    //   "slug": covid19Category && covid19Category.length ? covid19Category[0].slug :  "covid-19",
    //   "color": covid19Category && covid19Category.length ? covid19Category[0].color :  "#FF0000",
    //   "popular": tagItems
    // };
    let popItemsByCats = [];
    popularCats.forEach(slug =>
      popularAllItems.every(pop => {
        if(pop.slug == slug) {
          popItemsByCats.push(pop);
          return false;
        }
        return true;
      })
    );
    // let popularItems = [...popItemsByCats, popularCOVID19];
    let popularItems = [...popItemsByCats];
    const adMobileTop = getAdSlotByDevicePosition(true, ads, this.state.adMobileTop.position);
    const adMobileMiddle = getAdSlotByDevicePosition(true, ads, this.state.adMobileMiddle.position);
    const adMobileBottom = getAdSlotByDevicePosition(true, ads, this.state.adMobileBottom.position);
    const adMobileBottomRotate = getAdSlotByDevicePosition(true, ads, this.state.adMobileBottomRotate.position);
    const adDesktopTop = getAdSlotByDevicePosition(false, ads, this.state.adDesktopTop.position);
    const adDesktopMiddle = getAdSlotByDevicePosition(false, ads, this.state.adDesktopMiddle.position);
    const adDesktopBottom = getAdSlotByDevicePosition(false, ads, this.state.adDesktopBottom.position);
    const adDesktopBottomRotate = getAdSlotByDevicePosition(false, ads, this.state.adDesktopBottomRotate.position);
    const ssrDone = this.state.ssrDone;
    const showAds = ads.length > 0 && ssrDone;
    const showVideoSection = false;

    return (
      <div>
        <Head>
          <title>Breaking News & Top Stories | Duwun</title>
          {/* <title>Duwun: News and Entertainment - Latest Updates in Myanmar</title> */}
        </Head>
        <SeoMeta
            url={`${process.env.BACKEND_URL}`}
            type="article"
            title="Breaking News & Top Stories | Duwun"
            description='Read today’s news updates, exclusive interviews and viral videos. Stay up to date with breaking stories sent straight to your phone.'
            image={duwunCoverLogo}
            section='homepage'
          />
        <NProgress />
        <div className='ads-global-style'> 
          <Hidden only={['md', 'lg', 'xl']}>
            <div style={{textAlign : 'center'}}>
              {
                ssrDone && adMobileTop.id &&
                <AdSlot
                  sizes={adMobileTop.adSizes}
                  dfpNetworkId={adMobileTop.networkId}
                  adUnit={adMobileTop.adUnit}
                  targetingArguments={{ section: 'home' }}
                  shouldRefresh={()=> true}
                  slotId={this.state.adMobileTop.id}
                />
              }
            </div>
          </Hidden>
          <Hidden smDown>
            <div style={{textAlign : 'center'}}>
              {
                        showAds && adDesktopTop.id &&
                        <AdSlot
                          sizes={adDesktopTop.adSizes}
                          dfpNetworkId={adDesktopTop.networkId}
                          adUnit={adDesktopTop.adUnit}
                          targetingArguments={{ section: 'home' }}
                          shouldRefresh={()=> true}
                          slotId={this.state.adDesktopTop.id}
                        />
                    }
            </div>
          </Hidden>
        </div>
        {/* *************** Mobile View ****************** */}
        <Hidden only={['md', 'lg', 'xl']}>
          {/** ************* mobile carousel ******************* */}
          { carousel.length == 0 ? null : carousel.length == 1 ?  <DesktopFeatureCarouselItem carouselItem={carousel} /> : <MobileFeatureCarousel carouselItems={carousel} /> }
          {/** ************* end mobile carousel ******************* */}

          {/* <MobileView> */}
          <div className={classes.root}>
          <div className='mobileLayout'>

              {/* ********************* for popular ********************** */}
              {
                popularItems && popularItems.length > 0 && popularItems.map((item, index) => {
                  return(
                    <React.Fragment key={index}>
                      <MobileArticleCardsSection
                        contentTitle={item.slug === 'covid-19' ?
                          <Link route="tagItems" params={{ tag: item.slug }}>
                            <a className="anchor-link" style={{ color: 'inherit' }}>{item.name}
                            </a>
                          </Link> :
                          <PopularComponent>
                            <Link route="category" params={{ category: item.slug }}>
                              <a className="anchor-link" style={{ color: 'inherit' }}>{item.name}</a>
                            </Link>
                          </PopularComponent>}
                        color={item.color}
                        routeName={item.slug === 'covid-19' ? 'tagItems' : 'category'}
                        paramsName={item.slug === 'covid-19' ? { tag: item.slug } : {category: item.slug}}
                        readAll={<p style={{ paddingRight: 10 }}>Read All Latest <b> {item.name} Articles</b></p>}
                        newsItems={null}
                        items={item}
                        maxNum={3}
                      />
                      {index === 0 ? // for bottom ads
                        <>
                          <div className='onesignal-customlink-container' style={{ paddingBottom: 10 }}></div>                 

                           {/* ****************** for Video section **************** */}
                          {showVideoSection ?
                          <>
                            <div className='ads-style'>
                              {
                                showAds && adMobileBottom.id &&
                                <AdSlot
                                  sizes={adMobileBottom.adSizes}
                                  dfpNetworkId={adMobileBottom.networkId.toString()}
                                  adUnit={adMobileBottom.adUnit}
                                  targetingArguments={{ section: 'home' }}
                                  shouldRefresh={()=> true}
                                  slotId={this.state.adMobileBottom.id}
                                />
                              }
                            </div>                        
                          {/* ****************** for Video section **************** */}
                            { videos && videos.length &&
                              <div style={{ margin: '0px -16px 0px '}}>
                                <MobileVideoCardsSection
                                  contentTitle='Latest Videos'
                                  color="#fff"
                                  routeName='videos'
                                  paramsName={{}}
                                  readAll='View All Latest Videos'
                                  newsItems={null}
                                  items={videos}
                                  maxNum='3'
                                />
                              </div>
                            }
                            </>
                            : null
                          }
                        </>
                        :
                        // ********** for rotate ads ***********
                          <div className="ads-style">
                          {
                            showAds && adMobileBottomRotate.id && process.env.ENVIRONMENT !== "production" &&
                            <AdSlot
                              sizes={adMobileBottomRotate.adSizes}
                              dfpNetworkId={adMobileBottomRotate.networkId}
                              adUnit={adMobileBottomRotate.adUnit}
                              targetingArguments={{ section: 'home' }}
                              shouldRefresh={()=> true}
                              slotId={this.state.adMobileBottomRotate.id+"-"+index}
                            />
                            }
                        </div>
                      }
                     </React.Fragment>
                  )
                })
              }
              <ContentTitle contentTitle="Latest" color="#F9461E" />
              {posts.length > 0 && posts.map((post, index) => (
                <React.Fragment key={index}>
                  <MobileNewsItem key={index} item={post} />
                  {index === 4 ?
                    <div className="ads-style">
                      <div style={{textAlign : 'center'}}>
                        {
                              showAds && adMobileMiddle.id &&
                              <AdSlot
                                sizes={adMobileMiddle.adSizes}
                                dfpNetworkId={adMobileMiddle.networkId}
                                adUnit={adMobileMiddle.adUnit}
                                targetingArguments={{ section: 'home' }}
                                shouldRefresh={()=> true}
                                slotId={this.state.adMobileMiddle.id}
                              />
                            }
                      </div>
                    </div>
                        : null
                      }
                </React.Fragment>
              ))}              
          </div>

          </div>
          {/* </MobileView> */}
        </Hidden>
        {/* *************** Desktop View ****************** */}
        <Hidden smDown>
          {/* <BrowserView> */}
          {/* ****************** desktop feature carousel **************** */}
          <div className={classes.root}>
            <div className='layout'>
              <div style={{ display: 'block' }}>
                { carousel.length == 0 ? null : carousel.length == 1 ?  <DesktopFeatureCarouselItem carouselItem={carousel} /> : <DesktopFeatureCarousel carouselItems={carousel} /> }
              </div>

              <div className='ads-style'>
                <div style={{textAlign : 'center'}}>
                  {
                        showAds && adDesktopMiddle.id &&
                        <AdSlot
                          sizes={adDesktopMiddle.adSizes}
                          dfpNetworkId={adDesktopMiddle.networkId}
                          adUnit={adDesktopMiddle.adUnit}
                          targetingArguments={{ section: 'home' }}
                          shouldRefresh={()=> true}
                          slotId={this.state.adDesktopMiddle.id}
                        />
                      }
                </div>
              </div>
              {/* ****************** for popular **************** */}
              {
                popularItems && popularItems.length > 0 && popularItems.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <DesktopArticleCardsSection
                        contentTitle={item.slug === 'covid-19' ?
                          <Link route="tagItems" params={{ tag: item.slug }}>
                            <a className="anchor-link" style={{ color: 'inherit' }}>{item.name}
                            </a>
                          </Link>:
                          <PopularComponent>
                            <Link route="category" params={{ category: item.slug }}>
                              <a className="anchor-link" style={{ color: 'inherit' }}>{item.name}</a>
                            </Link>
                          </PopularComponent>}
                        isPopular
                        color={item.color}
                        routeName={item.slug === 'covid-19' ? 'tagItems' : 'category'}
                        paramsName={item.slug === 'covid-19' ? { tag: item.slug } : {category: item.slug}}
                        readAll= {<p style={{ paddingRight: 10 }}>Read All Latest <b> {item.name} Articles</b></p>}
                        newsItems={null}
                        items={item}
                      />
                      {
                        index === 0 ? // for bottom ads
                        <>
                          <div className='onesignal-customlink-container' style={{ padding: 10 }}></div>

                          {/* ****************** for Video section **************** */}
                          {showVideoSection ?
                          <>
                           <div className="ads-style">
                            {
                              showAds && adDesktopBottom.id &&
                              <AdSlot
                                sizes={adDesktopBottom.adSizes}
                                dfpNetworkId={adDesktopBottom.networkId}
                                adUnit={adDesktopBottom.adUnit}
                                targetingArguments={{ section: 'home' }}
                                shouldRefresh={()=> true}
                                slotId={this.state.adDesktopBottom.id}
                              />
                            }
                          </div>
                          { videos && videos.length &&
                              <DesktopVideoCardsSection
                                contentTitle='Latest Videos'
                                color="#fff"
                                routeName='videos'
                                paramsName={{}}
                                readAll='View All Latest Videos'
                                newsItems={null}
                                items={videos}
                              /> }
                            </>
                            : null
                          }
                        </>
                        :
                          // ********** for rotate ads ***********
                        <div className="ads-style">
                          {
                              showAds && adDesktopBottomRotate.id &&
                              <AdSlot
                                sizes={adDesktopBottomRotate.adSizes}
                                dfpNetworkId={adDesktopBottomRotate.networkId}
                                adUnit={adDesktopBottomRotate.adUnit}
                                targetingArguments={{ section: 'home' }}
                                shouldRefresh={()=> true}
                                slotId={this.state.adDesktopBottomRotate.id+"-"+index}
                              />
                            }
                        </div>
                      }
                    </React.Fragment>
                  )
                })
              }
              {/* ****************** latest Article **************** */}
              <DesktopLatestItems items={posts} />

              {/*{*/}
              {/*  isMobile ?*/}
              {/*    <div style={{ textAlign: 'center', position: 'absolute', left: '-1000px', top: '-250px' }}>*/}
              {/*      <AdSlot*/}
              {/*        sizes={[[300,250]]}*/}
              {/*        dfpNetworkId="73195129"*/}
              {/*        adUnit="eskimi-floating-ad-300x250"*/}
              {/*        shouldRefresh={()=> true}*/}
              {/*      />*/}
              {/*    </div> :null*/}
              {/*}*/}
           </div>
          </div>
          {/* </BrowserView> */}
        </Hidden>
      </div>
    );
  }
  componentDidMount () {

    window.DFPManagerLoaded = false;
    this.setState({
        ssrDone: true,
    });
  }

  componentDidUpdate(previousProps, previousState){
    // const categorySegment = this.props.url.query.category;
    DFPManager.setTargetingArguments({'section': 'home' });
    // DFPManager.refresh();
    // DFPManager.load();
    DFPManager.configureLazyLoad(true);
    DFPManager.configureDisableInitialLoad(true);
    // ref: https://developers.google.com/publisher-tag/guides/control-ad-loading

    DFPManager.setCollapseEmptyDivs(true);
    if (!window.DFPManagerLoaded)
      DFPManager.load();

    window.DFPManagerLoaded = true;
    if (isMobile) {
      DFPManager.refresh([this.state.adMobileTop.id]);
      DFPManager.refresh([this.state.adMobileMiddle.id]);
      DFPManager.refresh([this.state.adMobileBottom.id]);
      DFPManager.refresh([this.state.adMobileBottomRotate.id+"-1"]);
      DFPManager.refresh([this.state.adMobileBottomRotate.id+"-2"]);
      DFPManager.refresh([this.state.adMobileBottomRotate.id+"-3"]);
      DFPManager.refresh([this.state.adMobileBottomRotate.id+"-4"]);
      DFPManager.refresh([this.state.adMobileBottomRotate.id+"-5"]);
      // DFPManager.refresh([this.state.adMobileBottomRotate.id+"-6"]);
    }
    else {
      DFPManager.refresh([this.state.adDesktopTop.id]);
      DFPManager.refresh([this.state.adDesktopMiddle.id]);
      DFPManager.refresh([this.state.adDesktopBottom.id]);
      DFPManager.refresh([this.state.adDesktopBottomRotate.id+"-1"]);
      DFPManager.refresh([this.state.adDesktopBottomRotate.id+"-2"]);
      DFPManager.refresh([this.state.adDesktopBottomRotate.id+"-3"]);
      DFPManager.refresh([this.state.adDesktopBottomRotate.id+"-4"]);
      DFPManager.refresh([this.state.adDesktopBottomRotate.id+"-5"]);
      // DFPManager.refresh([this.state.adDesktopBottomRotate.id+"-6"]);
    }

    // DFPManager.refresh();

    // TagManager.dataLayer(tagManagerArgs)
    clearInnityAds();
  }
}

PostsIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
//   brands: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => {

  c.log('state.posts.popularPosts: ', state.posts.popularPosts)
  let allPopularPosts = state.posts.popularPosts.map(item => {return (item[item.slug].data)})
  c.log('allPopularPosts: ', allPopularPosts)
  // let allPopularItems = state.posts.popularPosts.map(item => {return (item.popularItems.data)})
  // c.log('allPopularItems: ', allPopularItems)
  // c.log('tagItems: ', state.posts.tag.data)

  return({
  posts: state.posts.list.data,
  links: state.posts.list.links,
  ads: state.posts.list.ads,
  carousel: state.posts.list.other.carousel,
  tagItems: null, //state.posts.tag.data,
  videos: state.posts.videos.data,
  // popularAllItems: state.posts.popularPosts.data
  // popularAllItems: allPopularItems
  popularAllItems: allPopularPosts
})};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPosts,
      // fetchTag,
      fetchVideos,
      fetchPopularPosts
      // fetchPopularItems
    },
    dispatch
  );

export default withRedux(Store, mapStateToProps, mapDispatchToProps)(
  withReduxSaga(withStyles(styles)(PostsIndex))
);
