import React from 'react';
import Grid from '@material-ui/core/Grid';
import DesktopNewsItem from "/components/Desktop/DesktopNewsItem";
import MobileNewsItem from "/components/Mobile/MobileNewsItem";

export function DesktopArticleCards(props) {
  const { items } = props;
  return (
    <React.Fragment>
      {items.length > 0 && items.map((item, index) => (
        <React.Fragment key={index}>
          <Grid item xs={3}>
            <DesktopNewsItem item={item} />
          </Grid>  
        </React.Fragment>
    ))}  
    </React.Fragment>
  );
}

export function MobileArticleCards(props) {
  const { items, maxNum, isInlineTeaser } = props;

  return (
    items.length > 0 ?
        items.map((item, index) => {
          const maxNumber = maxNum ? index < maxNum : item
          const removeDivider = index > 0 && ((index+1) % 3) === 0
          return(
            maxNumber ?
            <MobileNewsItem key={index} item={item} isInlineTeaser={isInlineTeaser} removeDivider={removeDivider} />
            : null
        )})
    : null
  );
}


