import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';

const styles = theme => ({ 
    mainHeading: {
        position: 'relative',
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: '31px',
        marginBottom: 16,
      },
      titleIcon: {
        paddingTop: 5,
        fontWeight: 'bold',
      }
})

class ContentTitle extends Component {
  render() {
    const { classes, contentTitle, color, customProps, isPopular, typoComponent, noCapitalize } = this.props;
    return (
          <Grid item xs={12}>
            <div style={{ marginBottom: 3, marginTop: this.props.width === 'xs' ? 0 : 16 , display: 'flex', color: color, padding: this.props.width === 'xs' ? 0 : '0px 10px'}}>
              <Typography component={typoComponent ? typoComponent : "div"} style={{ color: color, fontWeight: 'bold', textTransform: noCapitalize ? 'initial': 'capitalize' }} className={classes.mainHeading}>
                {contentTitle}
              </Typography>
              {
                  !isPopular && <span style={{fontSize: 24, fontWeight: 'bold', paddingLeft: 5}}>{` >`}</span>
                }
            </div>
          </Grid> 
     
    );
  }
}

export default compose(
    withWidth(),
    withStyles(styles))(ContentTitle);
