function getAdSlotSizes (sizes){
    return sizes.map((size) => [parseInt(size.width), parseInt(size.height)]);
}

function getAdslotKeyValue (key_value){
    let str = "{";
    key_value.forEach((el) => {
        str += `{'${el.key }' : '${ el.value}'}`;
    });
    return JSON.parse(`${str}}`);
}

function getAdSlotObject(ad){
    const obj = {};
    switch (ad.type) {
        case "DFP":
            obj.id = ad.id
            obj.networkId = ad.networkId.toString()
            obj.adUnit = ad.adUnit
            obj.adSizes = getAdSlotSizes(ad.sizes)
            obj.mobile = ad.isMobile
            obj.position = ad.position
            obj.targetingArguments = getAdslotKeyValue(ad.key_value)
            return obj;
        case "WIDGET" :
            obj.snippet = ad.snippet
            return obj;
    }
}

export default function getAdSlotByDevicePosition (isMobile, ads, position){
    let obj = {};
    for(const ad of ads){
        if(ad.isMobile === isMobile && !ad.is_brand){
            if(position == ad.position){
                obj = getAdSlotObject(ad);
                break;
            }
        }
    }
    return obj;
}

export function getBrandAdSlotByDevicePosition (isMobile, ads, position, is_brand){
    let obj = {};
    for(const ad of ads){
        if(ad.isMobile === isMobile){
            if(position == ad.position){
                if(ad.is_brand === is_brand){
                    obj = getAdSlotObject(ad);
                    break;
                }
            }
        }
    }
    return obj;
}

