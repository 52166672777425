import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { Link } from "routes";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

export function MobileViewReadAll(props) {
  const { items, readAll, routeName, paramsName, color, gtmClassName } = props; 
  const gtmClass = gtmClassName ? gtmClassName : "read_all_articles_gtm"
  return (
      <Grid item xs={12}>
        <Link route={routeName} params={paramsName}>
          <a className="anchor-link">
            <div className={gtmClass}>
              <Typography
                variant="body1"
                align="right"
                style={{
                    background: items.color || color,
                    cursor: 'pointer',
                    padding: 6, 
                    color: '#fff',
                    fontSize: 18,
                    lineHeight: '22px',
                    fontWeight: 300,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0px -16px 0px'
                  }}
              >
                {readAll}
               <img src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/arrowImage.svg" alt="arrow" style={{ marginLeft: 5 }} />
              </Typography>
            </div>
          </a>
        </Link>
      </Grid>
  );
}
function ReadAll(props) {
  const { items, readAll, routeName, paramsName, color, gtmClassName } = props; 
  const gtmClass = gtmClassName ? gtmClassName : "read_all_articles_gtm"
  return (
      <Grid item xs={12}>
        <Link route={routeName} params={paramsName}>
          <a className="anchor-link">
            <div className={gtmClass}>
              <Typography
                variant="body1"
                align="right"
                style={{
                    color: items.color || color,
                    cursor: 'pointer',
                    paddingBottom: 20, 
                    }}
              >
                {readAll}
                <Icon style={{ paddingTop: 6 }}>
                  keyboard_arrow_right
                </Icon>
              </Typography>
            </div>
          </a>
        </Link>
      </Grid>
  );
}


export default ReadAll;
