import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReadAll, { MobileViewReadAll } from './readAll';
import ContentTitle from "/components/contentTitle";
import { DesktopArticleCards, MobileArticleCards } from './articleCards';

export function DesktopArticleCardsSection(props) {
  const { items, contentTitle, color, routeName, paramsName, readAll, isPopular } = props;
  return (
    items.popular.length > 0 ?
    <Grid container>
        <ContentTitle isPopular contentTitle={contentTitle} color={color} />
        <DesktopArticleCards items={items.popular} />
        <MobileViewReadAll items={items} routeName={routeName} paramsName={paramsName} readAll={readAll} color={color} />
        {/* <ReadAll items={items} routeName={routeName} paramsName={paramsName} readAll={readAll} color={color} /> */}
    </Grid>
     :null
  );
}

export function MobileArticleCardsSection(props) {
    const { items, contentTitle, color, routeName, paramsName, readAll, isPopular } = props;
    return (
      items.popular.length > 0 ?
      <Grid container>
          <ContentTitle isPopular contentTitle={contentTitle} color={color} />
          <MobileArticleCards items={items.popular} maxNum={3} />
        <MobileViewReadAll items={items} routeName={routeName} paramsName={paramsName} readAll={readAll} color={color} />
      </Grid>
       :null
    );
  }


